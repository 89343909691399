import { getChargeInfo, postRecharge } from '@/api';
export default {
  data() {
    return {
      chosePayItem: 0,
      money: "",
      chargeList: [],
      moneyList: [],
      choseItem: null
    };
  },
  mounted() {
    getChargeInfo().then(res => {
      console.log(res);
      this.chargeList = res.data;
      //   this.chosePayItem = JSON.parse(res.data)[0].id
    });
  },
  methods: {
    chosePay(item) {
      this.chosePayItem = item.id;
      this.choseItem = item;
      //   this.maxMoney = item.max_money;
      //   this.minMoney = item.min_money
    },
    choseMoney(value) {
      this.money = value;
    },
    recharge() {
      if (!this.money) {
        this.$toast("请输入金额");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postRecharge({
        money: this.money,
        id: this.chosePayItem
      }).then(res => {
        this.$closeToast();
        if (res.url) {
          console.log(res.url);
          window.open(res.url, '_blank');
          //   window.location.href = res.url
        } else {
          this.$toast(res.msg);
          this.$store.dispatch("getUserInfo");
        }
        this.money = "";
      });
    }
  },
  watch: {
    choseItem(value) {
      this.moneyList = [50, 100, 500, 1000, 5000, 10000];
      this.moneyList = this.moneyList.filter(item => {
        return item >= value.min_money && item <= value.max_money;
      });
      console.log(this.moneyList);
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};